<template>
	<div class="f1 apps-page w-full dF fC f1 px-4">
		<div class="dF aC">
			<div class="dF aC f1">
				<h3>Promo Code</h3>
			</div>
		</div>
		<a-card :bodyStyle="{ padding: 0 }" class="my-3">
			<div>
				<a-table :rowKey="(e) => e.id" :columns="columns" :row-selection="rowSelection" :dataSource="promoCodes"
					:loading="loading" :pagination="{
						current: currentPage,
						total: totalPromoCodes,
						pageSize: pageSize,
					}" @change="handleChange">
					<div slot="type" slot-scope="promoCode">
						{{
							promoCode.type === "percentage"
								? "Percentage"
								: promoCode.type === "amount"
									? "Amount"
									: "Add On"
						}}
					</div>
					<div slot="value" slot-scope="promoCode">
						{{
							promoCode.type === "percentage"
								? promoCode.value + " %"
								: promoCode.type === "amount"
									? "$ " + promoCode.value
									: promoCode.value
						}}
					</div>
					<div slot="status" slot-scope="promoCode">
						<span class="button" v-if="promoCode.isExpired" style="background-color: #dc3545">EXPIRED</span>
						<span class="button" v-else-if="promoCode.published_at"
							style="background-color: #28c791">ACTIVE</span>
						<span v-else class="button" style="background-color: #ffc107">
							DRAFT
						</span>
					</div>
					<div slot="startDate" slot-scope="promoCode" style="color: var(--med-gray)">
						{{ getDate(promoCode.startDate) }}
					</div>
					<div slot="endDate" slot-scope="promoCode" style="color: var(--med-gray)">
						{{ getDate(promoCode.endDate) }}
					</div>
					<div slot="createdAt" slot-scope="promoCode" style="color: var(--med-gray)">
						{{ getDate(promoCode.createdAt) }}
					</div>
					<div slot="updatedAt" slot-scope="promoCode" style="color: var(--med-gray)">
						{{ getDate(promoCode.updatedAt) }}
					</div>
					<div slot="actions" slot-scope="promoCode" class="dF" style="justify-content: flex-end">
						<a-popover trigger="hover" placement="bottomRight" overlayClassName="popoverStyle">
							<div slot="content">
								<div class="popoverContent dF aC" @click="editPromoCode(promoCode)">
									Edit
								</div>
								<div class="popoverContent dF aC" @click="
									selectedPromoCode = promoCode;
								emailModal = true;
								">
									Email
								</div>
								<div class="popoverContent dF aC" @click="
									selectedPromoCode = promoCode;
								publishModal = true;
								">
									{{ promoCode.published_at ? "Unpublish" : "Publish" }}
								</div>
								<div class="popoverContent dF aC"
									@click="selectedPromoCode = promoCode; deleteModal = true;">
									Delete
								</div>
							</div>
							<div class="more-option-icon mr-4">
								<a-icon style="line-height: 40px" type="more" />
							</div>
						</a-popover>
					</div>
				</a-table>
			</div>
		</a-card>
		<a-modal v-model="publishModal"
			:title="`${selectedPromoCode.published_at ? 'Unpublish' : 'Publish'} Promo Code`"
			:ok-text="`${selectedPromoCode.published_at ? 'Unpublish' : 'Publish'}`" @ok="updatePromoCode"
			:centered="true">
			<p>
				Are you sure you want to
				{{ selectedPromoCode.published_at ? "Unpublish" : "Publish" }}
				this promo code
				<strong>
					{{ selectedPromoCode.code }}
				</strong>
				?
			</p>
		</a-modal>
		<a-modal v-model="deleteModal" title="Delete Promo Code" ok-text="Delete" @ok="deletePromoCode"
			:centered="true">
			<p>
				Are you sure you want to delete this promo code
				<strong>
					{{ selectedPromoCode.code }}
				</strong>
				?
			</p>
		</a-modal>
		<a-modal v-model="emailModal" title="Email Promo Code" ok-text="SEND" @ok="emailPromoCode" :centered="true">
			<a-form-model-item label="Recipient(s)" prop="recipients">
				<a-select mode="multiple" show-search placeholder="Choose a Recipient(s)" v-model="recipients"
					:filter-option="filterOption" option-filter-prop="email" size="large" style="width: 100%">
					<a-select-option v-for="(user, i) in allUsers" :key="(i + 9).toString(36) + i" :value="user.email">
						{{ user.email }}
					</a-select-option>
				</a-select>
			</a-form-model-item>
		</a-modal>
		<a-modal v-model="createNewPromoCode" ok-text="CREATE" @ok="createPromoCode" @cancel="closeNewPromoCode"
			:confirmLoading="confirmLoading" :width="900" class="add-promo-code">
			<template #title>
				Add New Promo Code
				<a-tooltip overlayClassName="add-promo-code-tooltip">
					<template #title>
						<div class="text-md">
							Promo Code Descriptions:
						</div>
						<br />
						<div>
							<strong>FREEINSTANCE</strong>: Provides a full discount on everything, including the base
							plan/license fee and any add-ons. Will have to be manually removed.
						</div>
						<br />
						<div>
							<strong>FREEPLAN</strong>: Applies to the subtotal. Discounts can be set as either a
							percentage or a fixed dollar amount. Must be offered on its own.
							If two or more services are being offered they must be grouped.
						</div>
						<br />
						<div>
							<strong>ADD-ONS</strong>: Applies to individual add-ons only and cannot be combined with
							other discounts. This can be set as either a percentage or a fixed dollar value.
						</div>
						<br />
						<div>
							<strong>Note</strong>: When creating new promo codes, you can set specific start and end
							dates to control the time frame of the promotion.
						</div>
					</template>
					<a-icon type="question-circle" style="font-size: 14px" />
				</a-tooltip>
			</template>
			<a-form-model ref="ruleForm" :model="promoCode">
				<a-row :gutter="16">
					<a-col :span="$mq == 'sm' ? 24 : 8">
						<a-form-model-item ref="name" label="Promo Code Name" prop="name" required>
							<a-input size="large" v-model="promoCode.name" placeholder="Please Enter Promo Code Name"
								required />
						</a-form-model-item>
					</a-col>
					<a-col :span="$mq == 'sm' ? 24 : 16">
						<a-form-model-item ref="description" label="Promo Code Description" prop="description">
							<a-textarea size="large" v-model="promoCode.description"
								placeholder="Please Enter Promo Code Description"
								:auto-size="{ minRows: 2, maxRows: 5 }" />
						</a-form-model-item>
					</a-col>
				</a-row>
				<a-row :gutter="16">
					<a-col :span="$mq == 'sm' ? 24 : 16">
						<a-form-model-item prop="code" label="Promo Code">
							<a-row :gutter="16">
								<a-col :span="$mq == 'sm' ? 24 : 12">
									<a-input v-model="promoCode.code" placeholder="Enter Promo Code" size="large"
										:disabled="promoCode.autogenerate">
									</a-input>
								</a-col>
								<a-col :span="$mq == 'sm' ? 24 : 12">
									<a-checkbox v-model="promoCode.autogenerate" @change="promoCode.code = ''">
										Auto Generate Promo Code
									</a-checkbox>
								</a-col>
							</a-row>
						</a-form-model-item>
					</a-col>
					<a-col :span="$mq == 'sm' ? 24 : 8">
						<a-form-model-item prop="appliesTo" label="Promo Code for">
							<a-select v-model="promoCode.appliesTo" size="large">
								<a-select-option value="user">
									Customer
								</a-select-option>
								<a-select-option value="partner">
									Partner
								</a-select-option>
							</a-select>
						</a-form-model-item>
					</a-col>
				</a-row>
				<a-row :gutter="16">
					<a-col :span="$mq == 'sm' ? 24 : 8">
						<a-form-model-item prop="type" label="Promo Code Type">
							<a-select v-model="promoCode.type" size="large">
								<a-select-option value="percentage">
									Percentage
								</a-select-option>
								<a-select-option value="amount">
									Amount
								</a-select-option>
								<a-select-option value="packageExtra">
									Add On
								</a-select-option>
							</a-select>
						</a-form-model-item>
					</a-col>
					<a-col v-if="promoCode.type === 'packageExtra'" :span="$mq == 'sm' ? 24 : 8">
						<a-form-model-item prop="package_extras" label="AddOn Type">
							<a-select v-model="promoCode.package_extras" size="large">
								<a-select-option v-for="(pkg, index) in packageExtras" :key="index" :value="pkg.id">
									{{ pkg.name }}
								</a-select-option>
							</a-select>
						</a-form-model-item>
					</a-col>
					<a-col :span="$mq == 'sm' ? 24 : 8">
						<a-form-model-item ref="value" :label="promoCodeValueLabel" prop="value" required>
							<a-input-number style="width: 300px; max-width: 100%" size="large" :min="1"
								v-model="promoCode.value" placeholder="Please Enter value" :formatter="formatterValue"
								:disabled="selectedAddOnType && ['onboarding', 'sms'].includes(selectedAddOnType.identifier)" />
						</a-form-model-item>
					</a-col>
				</a-row>
				<a-row :gutter="16">
					<a-col :span="$mq == 'sm' ? 24 : 12">
						<a-form-model-item label="Start DateTime" prop="startDate"
							:rules="req('Please enter the Promo Code Start DateTime')">
							<a-date-picker v-model="promoCode.startDate" placeholder="Promo Code Start DateTime"
								:showTime="true" size="large" required style="width: 100%">
							</a-date-picker>
						</a-form-model-item>
					</a-col>
					<a-col :span="$mq == 'sm' ? 24 : 12">
						<a-form-model-item label="End DateTime" prop="endDate"
							:rules="req('Please enter the Promo Code End DateTime')">
							<a-date-picker :disabled-date="disabledDate" v-model="promoCode.endDate"
								placeholder="Promo Code End DateTime" :showTime="true" size="large" required
								style="width: 100%">
							</a-date-picker>
						</a-form-model-item>
					</a-col>
				</a-row>
				<a-row :gutter="16">
					<a-col :span="$mq == 'sm' ? 24 : 12">
						<a-form-model-item ref="usageLimits" prop="usageLimits">
							<template slot="label">
								Usage Limit
								<a-tooltip overlayClassName="change-tooltip-color"
									title="Number of times promo code can be used for a project.">
									<a-icon type="question-circle" style="font-size: 14px" />
								</a-tooltip>
							</template>
							<a-input-number style="width: 500px; max-width: 100%" size="large"
								v-model="promoCode.usageLimits" placeholder="Please Enter Usage Limits" />
						</a-form-model-item>
					</a-col>
					<a-col :span="$mq == 'sm' ? 24 : 12">
						<a-form-item label="Single Use">
							<a-switch v-model="promoCode.singleUse" checked-children="On" un-checked-children="Off" />
						</a-form-item>
					</a-col>
				</a-row>
			</a-form-model>
		</a-modal>
	</div>
</template>

<script>
	import { mapMutations } from "vuex";
	import moment from "moment";
	import _ from "lodash";

	export default {
		data() {
			return {
				sortedInfo: {
					key: "createdAt",
					order: "DESC",
				},
				selectedRowKeys: [],
				selectedRowIds: [],
				promoCodes: [],
				recipients: [],
				loading: false,
				emailModal: false,
				publishModal: false,
				deleteModal: false,
				confirmLoading: false,
				currentPage: 1,
				totalPromoCodes: 0,
				pageSize: 10,
				selectedPromoCode: {},
				promoCode: {
					appliesTo: "user",
					autogenerate: false,
					name: "",
					description: "",
					code: "",
					type: "percentage",
					package_extras: null,
					value: 1,
					startDate: "",
					endDate: "",
					usageLimits: 0,
					singleUse: false,
				},
				labels: {
					"api_calls": "Free Months",
					"onboarding": "Free Quantity",
					"lot_door_fee": "Free Quantity",
					"online_video_chat_support": "Free Months",
					"sms": "Free Quantity"
				}
			};
		},
		computed: {
			createNewPromoCode: {
				get() {
					return this.$store.state.addNew["promoCode"];
				},
				set() {
					this.UPDATE_ADD_NEW({
						key: "promoCode",
						value: false,
					});
				},
			},

			allUsers() {
				return this.$store.state.allUsers;
			},

			packageExtras() {
				return this.$store.state.packageExtras || [];
			},

			search() {
				return this.$store.state.searchFilter.toLowerCase();
			},
			columns() {
				return [
					{
						title: "ID",
						key: "readableId",
						dataIndex: "readableId",
						sorter: true,
					},
					{
						title: "Name",
						key: "name",
						dataIndex: "name",
						sorter: true,
					},
					{
						title: "Description",
						key: "description",
						dataIndex: "description",
						sorter: true,
					},
					{
						title: "Code",
						key: "code",
						dataIndex: "code",
						sorter: true,
					},
					{
						title: "Type",
						key: "type",
						scopedSlots: { customRender: "type" },
						sorter: true,
					},
					{
						title: "Value",
						key: "value",
						scopedSlots: { customRender: "value" },
						sorter: true,
					},
					{
						title: "Status",
						key: "status",
						scopedSlots: { customRender: "status" },
						sorter: true,
					},
					{
						title: "Start Date",
						key: "startDate",
						scopedSlots: { customRender: "startDate" },
						sorter: true,
					},
					{
						title: "End Date",
						key: "endDate",
						scopedSlots: { customRender: "endDate" },
						sorter: true,
					},
					{
						title: "Date Created",
						key: "createdAt",
						scopedSlots: { customRender: "createdAt" },
						sorter: true,
					},
					{
						title: "",
						key: "actions",
						scopedSlots: { customRender: "actions" },
					},
				];
			},
			rowSelection() {
				const { selectedRowKeys } = this;
				return {
					selectedRowKeys,
					onChange: this.onSelectChange,
					hideDefaultSelections: false,
					onSelection: this.onSelection,
				};
			},

			selectedAddOnType() {
				if (this.promoCode.type === 'packageExtra') {
					let found = this.packageExtras.find(p => p.id === this.promoCode.package_extras);
					return found
				}
				return null
			},

			promoCodeValueLabel() {
				let label = 'Value';

				if (this.selectedAddOnType && this.labels[this.selectedAddOnType.identifier]) {
					label = this.labels[this.selectedAddOnType.identifier]
				}
				return label;
			}
		},
		watch: {
			search() {
				this.searchDebounce();
			},

			selectedAddOnType(value) {
				if (value && ['onboarding', 'sms'].includes(value.identifier)) {
					this.promoCode.value = 1
				}
			}
		},
		created() {
			this.getTotalPromoCodeCount();
			this.getPromoCodes();
			this.getPackagesExtra();
		},

		methods: {
			...mapMutations([
				"SET_PROMO_CODE",
				"UPDATE_SEARCH",
				"UPDATE_ADD_NEW",
				"SET_USERS",
			]),

			searchDebounce: _.debounce(function () {
				this.getTotalPromoCodeCount();
				this.getPromoCodes();
			}, 1000),

			async getTotalPromoCodeCount() {
				try {
					let searchQuery = "?_publicationState=preview";
					if (this.search) {
						searchQuery = `?_publicationState=preview&code=${this.search}`;
					}
					let { data } = await this.$api.get(
						`/promo-codes/count${searchQuery}`
					);
					this.totalPromoCodes = data;
				} catch (err) {
					if (err?.response?.status !== 400) {
						this.$message.error(this.$err(err, "Error while fetching promoCode count. Please try again!"))
					}
				}
			},

			async getPromoCodes() {
				this.loading = true;
				try {
					let start = (this.currentPage - 1) * this.pageSize;
					let searchQuery = "";
					if (this.search) {
						searchQuery = `&code=${this.search}`;
					}
					let { data } = await this.$api.get(
						`/promo-codes?_publicationState=preview&_start=${start}&_limit=${this.pageSize}&_sort=${this.sortedInfo.key}:${this.sortedInfo.order}${searchQuery}`
					);
					this.promoCodes = data.map((promoCode) => {
						if (!promoCode.endDate) return { ...promoCode, isExpired: false }
						const endMoment = moment(promoCode.endDate);
						const now = moment();
						const isExpired = now.isAfter(endMoment);
						return {
							...promoCode,
							isExpired
						};
					});
				} catch (err) {
					if (err?.response?.status !== 400) {
						this.$message.error(this.$err(err, "Error while fetching promoCode list. Please try again!"))
					}
				}
				this.loading = false;
			},

			createPromoCode() {
				this.$refs.ruleForm.validate((valid) => {
					if (valid) {
						if (!this.promoCode.code && !this.promoCode.autogenerate) {
							return this.$message.error(
								"Please Enter Promo Code or select Auto Generate Promo Code."
							);
						}
						if (!this.promoCode.value) {
							return this.$message.error(
								"Please Enter valid number for value."
							);
						}
						const startDateTime = +moment(
							this.promoCode.startDate
						).format("x");
						const endDateTime = +moment(this.promoCode.endDate).format(
							"x"
						);
						if (startDateTime > endDateTime) {
							return this.$message.error(
								"Start DateTime can't be after End DateTime. Please Enter valid Start DateTime."
							);
						}
						if (
							!this.promoCode.usageLimits &&
							!this.promoCode.singleUse
						) {
							return this.$message.error(
								"Please Enter Usage Limit or turn on Single Use."
							);
						}
						const promoCodeObj = {
							...this.promoCode,
							package_extras:
								this.promoCode.type === "packageExtra"
									? [this.promoCode.package_extras]
									: null,
						};

						this.confirmLoading = true;
						this.$api
							.post(`/promo-codes`, promoCodeObj)
							.then(() => {
								this.confirmLoading = false;

								this.$message.success("Successfully created new Promo Code.");
								this.closeNewPromoCode()
								this.getTotalPromoCodeCount();
								this.getPromoCodes();
							})
							.catch((err) => {
								this.confirmLoading = false;
								if (err?.response?.status !== 400) {
									this.$message.error(this.$err(err, "Error while creating new Promo Code. Please try again!"));
								}
							});
					} else {
						console.error("Invalid form details");
						return false;
					}
				});
			},

			closeNewPromoCode() {
				this.$refs.ruleForm.resetFields();
				this.UPDATE_ADD_NEW({
					key: "promoCode",
					value: false,
				});
			},

			async deletePromoCode() {
				try {
					await this.$api.delete(
						`/promo-codes/${this.selectedPromoCode.id}`
					);
					this.deleteModal = false;
					this.selectedPromoCode = {};
					this.getTotalPromoCodeCount();
					this.getPromoCodes();
				} catch (err) {
					if (err?.response?.status !== 400) {
						this.$message.error(this.$err(err, "Error while deleting promo code. Please try again!"))
					}
				}
			},

			async updatePromoCode() {
				try {
					await this.$api.put(
						`/promo-codes/${this.selectedPromoCode.id}`,
						{
							published_at: this.selectedPromoCode.published_at
								? null
								: new Date(),
						}
					);
					this.$message.success(
						"Promo Code updated successfully!"
					);
					this.selectedPromoCode = {};
					this.publishModal = false;
					this.getTotalPromoCodeCount();
					this.getPromoCodes();
				} catch (err) {
					if (err?.response?.status !== 400) {
						this.$message.error(this.$err(err, `Error while ${this.selectedPromoCode.published_at ? "Unpublish" : "Publish"} promo code. Please try again!`))
					}
				}
			},

			async emailPromoCode() {
				if (!this.recipients.length) {
					return this.$message.warn("Please select recipients first");
				}
				try {
					await this.$api.post(
						`/promo-codes/${this.selectedPromoCode.id}/email`,
						{
							recipients: this.recipients,
						}
					);
					this.$message.success(
						"Promo Code send successfully to selected emails!"
					);
					this.recipients = [];
					this.emailModal = false;
				} catch (err) {
					if (err?.response?.status !== 400) {
						this.$message.error(this.$err(err, "Error while promo code to selected emails. Please try again!"))
					}
				}
			},

			async getPackagesExtra() {
				try {
					const { data } = await this.$api.get("/package-extras");
					this.$store.commit("SET_PACKAGE_EXTRAS", data);
					this.promoCode.package_extras = this.packageExtras.length
						? this.packageExtras[0].id
						: null;
				} catch (err) {
					if (err?.response?.status !== 400) {
						this.$message.error(this.$err(err, "Error while fetching package-extras. Please try again!"))
					}
				}
			},

			getDate(date) {
				return moment(date).format("D/M/YYYY");
			},

			onSelectChange(selectedRowKeys, selectedRows) {
				this.selectedRowIds = selectedRows.map((i) => i.id);
				this.selectedRowKeys = selectedRowKeys;
			},

			editPromoCode(promoCode) {
				this.SET_PROMO_CODE(promoCode);
				this.$router.push(`/promoCode/${promoCode.id}`);
			},

			handleChange(pagination, filter, sorter) {
				if (pagination) {
					this.currentPage = pagination.current;
				}
				if (sorter && sorter.column) {
					this.sortedInfo = {
						key: sorter.columnKey,
						order: sorter.order === "descend" ? "DESC" : "ASC",
					};
				}
				this.getPromoCodes();
			},

			req: (msg) => ({ required: true, message: msg }),

			formatterValue(value) {
				if (this.promoCode.type === "percentage") {
					return value + " %";
				} else if (this.promoCode.type === "amount") {
					return "$ " + value;
				}
				return value;
			},

			disabledDate(current) {
				if (!this.promoCode.startDate) {
					return;
				}
				// Can not select days before start date
				return (
					current &&
					current < moment(this.promoCode.startDate).endOf("day")
				);
			},

			filterOption(input, option) {
				return (
					option.componentOptions.children[0].text
						.toLowerCase()
						.indexOf(input.toLowerCase()) >= 0
				);
			},
		},

		beforeDestroy() {
			this.UPDATE_SEARCH("");
		},
	};
</script>

<style lang="scss" scoped>
	.button {
		color: #fff;
		border-radius: 4px;
		font-size: 14px;
		text-align: center;
		padding: 4px 10px;
	}

	.popoverContent {
		height: max-content;
		line-height: 30px;
		padding: 0 10px;
	}

	.popoverContent:hover {
		background-color: var(--off-white-light);
		color: var(--primary);
		cursor: pointer;
	}
</style>

<style lang="scss">
	.popoverStyle .ant-popover-inner-content {
		padding: 5px;
		background-color: white;
		border-radius: 20px;
	}

	.add-promo-code-tooltip .ant-tooltip-content .ant-tooltip-inner {
		background-color: #3f3356;
		width: 600px;
	}
</style>
